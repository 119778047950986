;(function() {
    var body = document.body
    var script = document.createElement("script")
    script.type = "text/javascript"
    script.async = "async"
    script.src = "https://www.google.com/recaptcha/api.js?onload=renderGoogleInvisibleRecaptcha&render=explicit"
    script.onload = function() {
        const render = (form, $field) => {
            const recaptchaId = grecaptcha.render($field, {
                sitekey: window.recaptcha_key,
                size: "invisible",
                badge: "bottomright",
                callback: () => HTMLFormElement.prototype.submit.call(form)
            })

            form.addEventListener("submit", (e) => {
                e.preventDefault()
                grecaptcha.execute(recaptchaId)
            })
        }
        window.renderGoogleInvisibleRecaptcha = function() {
            for (var i = 0; i < document.forms.length; ++i) {
                const form = document.forms[i]
                const $field = form.querySelector(".g-recaptcha")

                if ($field) {
                    render(form, $field)
                }
            }
        }
    }

    setTimeout(() => {
        body.appendChild(script)
    }, 2000)
})()
